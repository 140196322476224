<template>
  <div>
    <v-dialog v-model="editUserDialog" persistent max-width="500px">
      <v-card>
        <v-card-title v-if="!$vuetify.breakpoint.mobile">
          Edit User
        </v-card-title>
        <v-card-title v-if="$vuetify.breakpoint.mobile" />
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                label="First Name"
                v-model="userData.first_name"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                label="Last Name"
                v-model="userData.last_name"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                label="Username"
                v-model="userData.username"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                dense
                outlined
                label="Password"
                type="password"
                clearable
                v-model="userData.password"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                dense
                outlined
                label="Email"
                v-model="userData.email"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-select
                multiple
                :items="availableRoles"
                label="Roles"
                v-model="userData.roles"
              >
                <template v-slot:[`selection`]="{ index }">
                  <v-chip v-if="index === 0">{{
                    `${userData.roles.length} Selected`
                  }}</v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-checkbox
                outlined
                label="Active"
                v-model="userData.active"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="$emit('cancel')">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="submit()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'editUser',
  data() {
    return {
      editUserDialog: true,
      availableRoles: ['associate', 'super', 'WFH'],
    };
  },

  props: {
    userData: Object,
  },
  methods: {
    async submit() {
      const res = await this.$axios.put('/account/users/edit', {
        data: this.userData,
      });
      console.log(res);
      this.$emit('done');
    },
  },
};
</script>
