<template>
  <v-row class="justify-center">
    <EditUser
      v-if="editUserDialog"
      @cancel="editUserDialog = false"
      @done="
        getAllUsers();
        editUserDialog = false;
      "
      :userData="Object.assign({}, activeUser)"
    />
    <v-col cols="12">
      <div>
        <v-data-table
          :loading="tableLoading"
          :items="tableLoading ? [{}] : users"
          class="elevation-1"
          :headers="headers"
          item-key="id"
          :mobile-breakpoint="0"
          :items-per-page="200"
          group-by="active"
          :group-desc="true"
          height="100%"
          fixed-header
          hide-default-footer
          sort-by="first_name"
        >
          <template v-slot:[`item.id`]="{ item }">
            <a
              target="_blank"
              :href="
                `https://mobiledemand-barcode.azurewebsites.net/barcode/image?content=${item.id}&size=200&symbology=CODE_128&format=png&text=false`
              "
              >{{ item.id }}</a
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon medium class="mr-2" @click="editUser(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <a target="_blank" :href="`mailto:${item.email}`">{{
              item.email
            }}</a>
          </template>
        </v-data-table>
        <!-- End Table -->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import EditUser from '../components/EditUser.vue';
export default {
  name: 'Users',
  components: {
    EditUser,
  },
  data() {
    return {
      users: [],
      tableLoading: true,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'ID', value: 'id' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Username', value: 'username' },
        { text: 'Active', value: 'active' },
        { text: 'Email', value: 'email' },
        { text: 'Roles', value: 'roles' },
      ],
      activeUser: {},
      editUserDialog: false,
    };
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    async getAllUsers() {
      let response = await this.$axios.get(
        'account/users/getAllUsers',
      );
      this.users = response.data.users;
      this.tableLoading = false;
    },
    async editUser(user) {
      this.activeUser = user;
      this.editUserDialog = true;
    },
  },
};
</script>
