var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"justify-center"},[(_vm.editUserDialog)?_c('EditUser',{attrs:{"userData":Object.assign({}, _vm.activeUser)},on:{"cancel":function($event){_vm.editUserDialog = false},"done":function($event){_vm.getAllUsers();
      _vm.editUserDialog = false;}}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.tableLoading,"items":_vm.tableLoading ? [{}] : _vm.users,"headers":_vm.headers,"item-key":"id","mobile-breakpoint":0,"items-per-page":200,"group-by":"active","group-desc":true,"height":"100%","fixed-header":"","hide-default-footer":"","sort-by":"first_name"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("https://mobiledemand-barcode.azurewebsites.net/barcode/image?content=" + (item.id) + "&size=200&symbology=CODE_128&format=png&text=false")}},[_vm._v(_vm._s(item.id))])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.email",fn:function(ref){
      var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }