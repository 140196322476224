var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.editSessionDialog)?_c('EditSession',{attrs:{"sessionData":_vm.selectedPunch[0]},on:{"cancel":function($event){_vm.editSessionDialog=false},"done":function($event){_vm.getSessions(); _vm.editSessionDialog=false;}}}):_vm._e(),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.punchData,"item-key":"id","sortBy":['dateObjIn'],"sort-desc":[true],"mobile-breakpoint":0,"single-select":true,"items-per-page":200,"height":"50vh","fixed-header":"","dense":"","show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Edit Sessions")]),_c('v-spacer'),_c('div',[_c('v-btn',{class:!_vm.$vuetify.breakpoint.xsOnly == true
                      ? 'mx-4'
                      : 'mx-2',attrs:{"disabled":!_vm.selectedPunch[0],"color":"blue-grey lighten-1"},on:{"click":function($event){_vm.editSessionDialog = true;}}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.xsOnly == true ? 'edit' : 'Edit')+" ")])],1),(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.$vuetify.breakpoint.xsOnly == true ? 'add' : 'new session'))])]}}]),model:{value:(_vm.newSessionDialog),callback:function ($$v) {_vm.newSessionDialog=$$v},expression:"newSessionDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" New Session ")]),_c('v-card-text',[_c('v-dialog',{ref:"dateDialog",attrs:{"return-value":_vm.newPunchDate,"width":"290px"},on:{"update:returnValue":function($event){_vm.newPunchDate=$event},"update:return-value":function($event){_vm.newPunchDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of session","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.newPunchDate),callback:function ($$v) {_vm.newPunchDate=$$v},expression:"newPunchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","min":'2020-12-27'},on:{"input":function($event){return _vm.$refs.dateDialog.save(_vm.newPunchDate)}},model:{value:(_vm.newPunchDate),callback:function ($$v) {_vm.newPunchDate=$$v},expression:"newPunchDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateDialog = false}}},[_vm._v("Cancel")])],1)],1),_c('v-dialog',{ref:"timeInDialog",attrs:{"close-on-content-click":false,"return-value":_vm.newPunchTimeIn,"width":"290px","persistent":""},on:{"update:returnValue":function($event){_vm.newPunchTimeIn=$event},"update:return-value":function($event){_vm.newPunchTimeIn=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time in","prepend-icon":"mdi-clock-in","readonly":""},model:{value:(_vm.newPunchTimeIn),callback:function ($$v) {_vm.newPunchTimeIn=$$v},expression:"newPunchTimeIn"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeInDialog),callback:function ($$v) {_vm.timeInDialog=$$v},expression:"timeInDialog"}},[_c('v-time-picker',{model:{value:(_vm.newPunchTimeIn),callback:function ($$v) {_vm.newPunchTimeIn=$$v},expression:"newPunchTimeIn"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeInDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timeInDialog.save(
                                      _vm.newPunchTimeIn
                                    )}}},[_vm._v("OK")])],1)],1),_c('v-dialog',{ref:"timeOutDialog",attrs:{"close-on-content-click":false,"return-value":_vm.newPunchTimeOut,"width":"290px","persistent":""},on:{"update:returnValue":function($event){_vm.newPunchTimeOut=$event},"update:return-value":function($event){_vm.newPunchTimeOut=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":!_vm.newPunchTimeIn,"label":"Time out","prepend-icon":"mdi-clock-out","readonly":""},model:{value:(_vm.newPunchTimeOut),callback:function ($$v) {_vm.newPunchTimeOut=$$v},expression:"newPunchTimeOut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeOutDialog),callback:function ($$v) {_vm.timeOutDialog=$$v},expression:"timeOutDialog"}},[_c('v-time-picker',{attrs:{"min":_vm.newPunchTimeIn},model:{value:(_vm.newPunchTimeOut),callback:function ($$v) {_vm.newPunchTimeOut=$$v},expression:"newPunchTimeOut"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timeOutDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timeOutDialog.save(
                                      _vm.newPunchTimeOut
                                    )}}},[_vm._v("OK")])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newSessionDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"disabled":!_vm.newPunchTimeOut ||
                            !_vm.newPunchDate ||
                            _vm.newPunchTimeOut.localeCompare(
                              _vm.newPunchTimeIn
                            ) < 0,"color":"blue darken-1","text":""},on:{"click":function($event){_vm.newSessionDialog = false;
                          _vm.makeNewSession();}}},[_vm._v("Save")])],1)],1)],1)],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-card',{staticClass:"d-flex justify-center",attrs:{"tile":"","elevation":"0"}},[_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.payPeriodNumber == 0,"color":"primary"},on:{"click":_vm.prevPayPeriod}},[_vm._v("Previous")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"disabled":_vm.payPeriodNumber == _vm.latestPayPeriod,"color":"primary"},on:{"click":_vm.nextPayPeriod}},[_vm._v("Next")])],1)],1)]},proxy:true}]),model:{value:(_vm.selectedPunch),callback:function ($$v) {_vm.selectedPunch=$$v},expression:"selectedPunch"}})],1),_c('div',{staticClass:"d-flex"},[_c('v-card',{staticClass:"mx-auto mt-4",attrs:{"elevation":"2"}},[_c('v-card-subtitle',{staticClass:"black--text"},[_c('div',[_vm._v("Week 1 Hours: "+_vm._s(_vm.week1HoursWorked.toFixed(2)))]),_c('div',[_vm._v("Week 2 Hours: "+_vm._s(_vm.week2HoursWorked.toFixed(2)))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }